// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Grid,
  Dialog,
  DialogContent,
  TextField,
  Checkbox,
  IconButton,
  InputAdornment,
  Alert,
  Snackbar,
  Select,
  MenuItem,
  FormControl
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {  starIcon, logoIcon } from "./assets";
import Header from "../../navigationmenu/src/Header.web";
import Footer from "../../navigationmenu/src/Footer.web";
import * as Yup from "yup";
import { ValueType } from "react-select";
import { Edit as EditIcon, Close as CloseIcon } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import { Formik, FormikErrors, FormikTouched } from "formik";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Typography , CircularProgress, Backdrop } from "@material-ui/core";
import GoogleAutocomplete from "react-google-autocomplete";

import CustomFormController, {
  Props,
  Touched,
  Error,
  Dropdown,
} from "./CustomFormController.web";
import "./CustomForm.css"

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    }
  },
  typography: {
    "fontFamily": `"Museo Sans", sans-serif`,
  },
  overrides: {
    MuiButton: {
        root: {
          fontFamily: 'Museo Sans, sans-serif',
        }
    }
  }
});

// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userSchema = () => {
    return Yup.object().shape({
      

      business_name: Yup.string().required("*Business name is required!"),
      user_name: Yup.string().required("*User name is required!"),
      main_contact: Yup.string().required("*Main contact name is required!"),
      position: Yup.string().required("*Position is required!"),
      business_phone_num: Yup.string()
        .required("*Business phone number is required!")
        .matches(/^\d+$/, "Must contain only numeric digits"),
      main_contact_phone_num: Yup.string()
        .required("*Main contact phone number is required!")
        .matches(/^\d+$/, "Must contain only numeric digits"),
      business_email: Yup.string().email("*Invalid email format").required("*Email is required!"),
      business_address: Yup.string().required("*Address is required!"),
      business_city: Yup.string().required("*City is required!"),
      business_state: Yup.string().required("*State is required!"),
      business_zip: Yup.string()
      .min(5, 'Zip Code must be of 5 numbers')
      .required('*ZipCode is required!'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .test(
          'password-requirements',
          'Please check password Requirements',
          (value) => {
            const lower = /[a-z]/.test(value);
            const upper = /[A-Z]/.test(value);
            const number = /\d/.test(value);
            const special = /[!@#$^&*+=]/.test(value);
            const count = [lower, upper, number, special].filter(Boolean).length;
            return count >= 2;
          }
        )
        .required('Password is required!'),

      re_enter_password: Yup.string().oneOf([Yup.ref('password'), null], "*Passwords must match").required("*Please re-enter your password!")
    });
  };

  getErrorMessage = (
    touched: FormikTouched<Touched>,
    errors: FormikErrors<Error>,
    value: string
  ) => {
    return (
      touched[value as keyof Error] &&
      errors[value as keyof Error] && (
        <Typography
          style={{ marginTop: "-8px", fontSize: "12px", color: "#f94b4b",textAlign:"right"  }}
        >
          {errors[value as keyof Error]}
        </Typography>
      )
    );
  };

  getValue = (event: ValueType<Dropdown, false>) => {
    return event ? event.value : "";
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <Box style={{ overflowX: "hidden" }}>
        <Header {...this.props} />
        <Box className="mainContainerCustom">
          <Box className="live-area">
            <Box className="formContainerCustom">
              <Box
                className="bar-hopping-logo-registration"
              >
                <img className="logo-width" src={logoIcon} />
              </Box>
              <Formik
                data-test-id="formik1"
                initialValues={{
                  business_name: this.state.business_name,
                  user_name:this.state.user_name,
                  main_contact: this.state.main_contact,
                  position: this.state.position,
                  business_phone_num: this.state.business_phone_num,
                  main_contact_phone_num: this.state.main_contact_phone_num,
                  business_email: this.state.business_email,
                  business_address: this.state.business_address,
                  business_city: this.state.business_city,
                  business_state: this.state.business_state,
                  business_zip: this.state.business_zip,
                  password: this.state.password,
                  re_enter_password: this.state.re_enter_password
                }}
                enableReinitialize={true}
                validationSchema={this.userSchema}
                onSubmit={(values) => { this.onAddTask(); }}
              >
                {({ errors, touched, setFieldValue, handleSubmit }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container className="grid-container-custom">
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className="muiGrid-item-1"
                      >

                        <Box className="txtFieldStyle">
                          <img className="star-icon-signup" src={starIcon} />
                          <Box className="textfield-error-wrapper">
                          <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            placeholder="Name of Business"
                            className="textStyle"
                            inputProps={{maxLength:20}}
                            data-test-id={"business_nameID"}
                            fullWidth
                            value={this.state.business_name}
                            name="business_name"
                            onChange={(event) => {
                              this.inputValueChange("business_name", event.target.value);
                              setFieldValue("business_name", event.target.value);
                            }}
                          />
                          {this.getErrorMessage(touched, errors, "business_name")}
                          {this.state.invalidBusinessName && <div className="invalid-error-backend-customform">{this.state.invalidBusinessName}</div>}
                          </Box>
                        </Box>
                        <Box className="txtFieldStyle">
                          <img className="star-icon-signup" src={starIcon} />
                          <Box className="textfield-error-wrapper">
                          <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            placeholder="User Name"
                            inputProps={{maxLength:20}}
                            className="textStyle"
                            data-test-id={"user_nameID"}
                            fullWidth
                            value={this.state.user_name}
                            name="user_name"
                            onChange={(event) => {
                              this.inputValueChange("user_name", event.target.value);
                              setFieldValue("user_name", event.target.value);
                            }}
                          />
                          {this.getErrorMessage(touched, errors, "user_name")}
                          {this.state.invalidUserName && <div className="invalid-error-backend-customform">{this.state.invalidUserName}</div>}
                          </Box>
                        </Box>
                        <Box className="txtFieldStyle">
                          <img className="star-icon-signup" src={starIcon} />
                          <Box className="textfield-error-wrapper">
                          <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            data-test-id={"main_contact_nameID"}
                            className="textStyle"
                            placeholder="Main Contact"
                            value={this.state.main_contact}
                            fullWidth
                            name="main_contact"
                            onChange={(event) => {
                              this.inputValueChange("main_contact", event.target.value);
                              setFieldValue("main_contact", event.target.value);
                            }}
                          />
                          {this.getErrorMessage(touched, errors, "main_contact")}
                          {this.state.invalidContactName && <div className="invalid-error-backend-customform">{this.state.invalidContactName}</div>}
                          </Box>
                        </Box>
                        <Box className="txtFieldStyle">
                          <img className="star-icon-signup" src={starIcon} />
                          <Box className="textfield-error-wrapper">
                          <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            
                            data-test-id={"positionID"}
                            className="textStyle"
                            placeholder="Position"
                            value={this.state.position}
                            fullWidth
                            name="position"
                            onChange={(event) => {
                              this.inputValueChange("position", event.target.value);
                              setFieldValue("position", event.target.value);
                            }}
                          />
                          {this.getErrorMessage(touched, errors, "position")}
                          {this.state.invalidPosition && <div className="invalid-error-backend-customform">{this.state.invalidPosition}</div>}
                          </Box>
                        </Box>
                        <Box className="txtFieldStyle">
                          <img className="star-icon-signup" src={starIcon} />
                          <Box className="textfield-error-wrapper">
                          <TextField
                            variant="outlined"
                            margin="normal"
                            placeholder="Business Phone Number"
                            size="small"
                            inputProps={{maxLength: 10}}
                            className="textStyle"
                            data-test-id={"business_phone_num"}
                            value={this.state.business_phone_num}
                            fullWidth
                            name="business_phone_num"
                            onChange={(event) => {
                              this.inputValueChange("business_phone_num", event.target.value);
                              setFieldValue("business_phone_num", event.target.value);
                            }}
                          />
                          {this.getErrorMessage(touched, errors, "business_phone_num")}
                          </Box>
                        </Box>
                        <Box className="txtFieldStyle">
                          <img className="star-icon-signup" src={starIcon} />
                          <Box className="textfield-error-wrapper">
                          <TextField
                            variant="outlined"
                            margin="normal"
                            placeholder="Main Contact Cell Number"
                            inputProps={{maxLength: 10}}
                            data-test-id={"main_contact_phone_num"}
                            className="textStyle"
                            value={this.state.main_contact_phone_num}
                            size="small"
                            fullWidth
                            name="main_contact_phone_num"
                            onChange={(event) => {
                              this.inputValueChange("main_contact_phone_num", event.target.value);
                              setFieldValue("main_contact_phone_num", event.target.value);
                            }}
                          />
                          {this.getErrorMessage(touched, errors, "main_contact_phone_num")}
                          </Box>
                        </Box>
                        <Box className="txtFieldStyle">
                        <img className="star-icon-signup" src={starIcon} />
                        <Box className="textfield-error-wrapper">
                          <TextField
                            variant="outlined"
                            margin="normal"
                            placeholder="Email"
                            size="small"
                            className="textStyle"
                            data-test-id={"business_emailId"}
                            value={this.state.business_email}
                            fullWidth
                            name="business_email"
                            onChange={(event) => {
                              this.inputValueChange("business_email", event.target.value);
                              setFieldValue("business_email", event.target.value);
                            }}
                          />
                          {this.getErrorMessage(touched, errors, "business_email")}
                          {this.state.invalidEmail && <div className="invalid-error-backend-customform">Email has already been taken</div>}
                          </Box>
                        </Box>
                        <Box className="authorized">
                          <Checkbox className="checkboxStyle" onChange={(e) =>
                            this.setSignUpFieldsCheckbox("agree_Owner", e.target.checked)}
                            checked={this.state.agree_Owner}
                            data-test-id="agreeOwnerBox" />
                          <Typography variant="caption" className="authorized-typography">
                            I am an authorized owner of this business and/ or authorized by an owner to create/manage the content and information on this site.
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className="muiGrid-item-2"
                      >
                        <Box className="txtFieldStyle">
                          <Box className="textfield-error-wrapper-website">
                          <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            placeholder="Website"
                            className="textStyle"
                            style={{  marginLeft: "20px" }}
                            data-test-id={"WebsiteId"}
                            value={this.state.website}
                            fullWidth
                            name="website"
                            onChange={(event) => {
                              this.inputValueChange("website", event.target.value);
                            }}
                          />
                          </Box>
                        </Box>
                        <Box className="txtFieldStyle">
                          <img className="star-icon-signup" src={starIcon} />
                            <Box className="textfield-error-wrapper">
                              <GoogleAutocomplete
                                className="textStyle adressstyle"
                                data-test-id="business_addressId"
                                defaultValue={this.state.business_address}
                                onPlaceSelected={(place) => {
                                  const latitude = place.geometry?.location?.lat();
                                  const longitude = place.geometry?.location?.lng();
                                  const streetAddress = place.address_components?.find(component => component.types.includes("route"))?.long_name;
                                  const streetNumber = place.address_components?.find(component => component.types.includes("street_number"))?.long_name;
                                  const address = streetNumber && streetAddress ? `${streetNumber} ${streetAddress}` : streetAddress || "";
                                  const cityTypes = ["locality", "sublocality"];
                                  const city = cityTypes.reduce((acc:any, type:any) => 
                                    acc || place.address_components?.find(component => component.types.includes(type))?.long_name, ""
                                  );                                                               
                                  const state = place.address_components?.find(component => component.types.includes("administrative_area_level_1"))?.long_name || "";
                                  const zipCode = place.address_components?.find(component => component.types.includes("postal_code"))?.long_name || "";
                                  this.inputValueChange("business_address", address);
                                  this.inputValueChange("business_city", city);
                                  this.inputValueChange("business_state", state);
                                  this.inputValueChange("business_zip", zipCode);
                                  this.inputValueChange("longitude", longitude ?? 0);
                                  this.inputValueChange("latitude", latitude ?? 0);
                                  setFieldValue("business_address", address);
                                }}
                                placeholder="Address"
                                options={{
                                  types: ["address"],
                                  componentRestrictions: { country: "us" },
                                }}
                                style={{margin:'16px 0px 8px 0px'}}
                              />
                              {this.getErrorMessage(touched, errors, "business_address")}
                            </Box>
                        </Box>
                        <Box className="txtFieldStyle">
                          <TextField
                            variant="outlined"
                            margin="normal"
                            placeholder="Address 2"
                            className="textStyle"
                            data-test-id={"address2ID"}
                            value={this.state.address2}
                            style={{ marginLeft: "20px" }}
                            size="small"
                            fullWidth
                            name="address2"
                            onChange={(event) => {
                              this.inputValueChange("address2", event.target.value);
                              setFieldValue("address2", event.target.value);
                            }}
                          />
                          {this.getErrorMessage(touched, errors, "address2")}
                        </Box>
                        <Box className="txtFieldStyle">
                          <img className="star-icon-signup" src={starIcon} />
                          <Box className="textfield-error-wrapper">
                          <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            data-test-id={"business_cityID"}
                            className="textStyle"
                            value={this.state.business_city}
                            placeholder="City"
                            fullWidth
                            name="business_city"
                            onChange={(event) => {
                              this.inputValueChange("business_city", event.target.value);
                              setFieldValue("business_city", event.target.value);
                            }}
                          />
                          {this.getErrorMessage(touched, errors, "business_city")}
                          </Box>
                        </Box>
                        <Box className="textfield-error-wrapper">
                          <Box className="txtFieldStyle">
                            <img className="star-icon-signup" src={starIcon} />
                            <Box className="textfield-error-wrapper">
                              <FormControl>
                                <Select
                                  sx={{
                                    '.MuiOutlinedInput-notchedOutline': {
                                      borderColor: "#712220",
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                      borderColor: "#712220",
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                      borderColor: "#712220",
                                    },
                                    '& .MuiInputBase-input': {
                                      color: this.state.business_state ? "#000" : "#712220",
                                      width:'7.667vw',
                                      maxWidth:'7.667vw',
                                      overflow:'hidden'
                                    },
                                  }}
                                  className="dropDownStyle"
                                  value={this.state.business_state}
                                  onChange={(event) => {
                                    this.inputValueChange("business_state", event.target.value);
                                    setFieldValue("business_state", event.target.value);
                                  }}
                                  data-test-id="business_stateId"
                                  inputProps={{ 'aria-label': 'Without label' }}
                                  displayEmpty
                                  disableUnderline
                                >
                                  <MenuItem value="" style={{ display: "none" }}>
                                    State
                                  </MenuItem>
                                  {this.state.statesOptions.map((state: any) => (
                                    <MenuItem
                                      className="menuitem-list-catalogue"
                                      key={state.name}
                                      value={state.name}
                                    >
                                      {state.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                            <Box className="textfield-error-wrapper">
                            <TextField
                              variant="outlined"
                              margin="normal"
                              placeholder="Zip"
                              size="small"
                              inputProps={{maxLength: 5}}
                              className="textStyle"
                              value={this.state.business_zip}
                              data-test-id={"business_zipId"}
                              fullWidth
                              name="business_zip"
                              onChange={(event) => {
                                this.inputValueChange("business_zip", event.target.value);
                                setFieldValue("business_zip", event.target.value);
                              }}
                            />
                            </Box>
                          </Box>
                            <div style={{ marginRight: '22px', marginLeft: '22px', display: 'flex', justifyContent: 'space-between' }}>
                              <div style={{ flexGrow: 1, textAlign: 'left' }}>
                                {this.getErrorMessage(touched, errors, "business_state")}
                              </div>
                              <div style={{ flexGrow: 1, textAlign: 'right' }}>
                                {this.getErrorMessage(touched, errors, "business_zip")}
                              </div>
                            </div>
                          {this.state.invalidZip && <div className="invalid-error-backend-customform-zip">{this.state.invalidZip}</div>}
                        </Box>
                          <Box className="txtFieldStyle">
                            <img className="star-icon-signup" src={starIcon} />
                            <Box className="textfield-error-wrapper">
                            <TextField
                              variant="outlined"
                              margin="normal"
                              placeholder="Password"
                              type={this.state.showPassword ? "text" : "password"}
                              size="small"
                              className="textStyle"
                              data-test-id={"passwordTextId"}
                              value={this.state.password}
                              fullWidth
                              name="password"
                              onChange={(event) => {
                                const valueWithoutSpaces = event.target.value.replace(/\s+/g, "");
                                this.inputValueChange("password", valueWithoutSpaces);
                                setFieldValue("password", valueWithoutSpaces);
                              }}                          
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPass}
                                      onMouseDown={this.handleMouseDownPassword}
                                    >
                                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                            {this.getErrorMessage(touched, errors, "password")}
                            </Box>
                          </Box>
                          <Box className="txtFieldStyle">
                            <img className="star-icon-signup" src={starIcon} />
                            <Box className="textfield-error-wrapper">
                            <TextField
                              variant="outlined"
                              margin="normal"
                              placeholder="Re enter Password"
                              type={this.state.showConfirmPassword ? "text" : "password"}
                              data-test-id={"confirm_passwordId"}
                              value={this.state.re_enter_password}
                              className="textStyle"
                              size="small"
                              fullWidth
                              name="re_enter_password"
                              onChange={(event) => {
                                const valueWithoutSpaces = event.target.value.replace(/\s+/g, "");
                                this.inputValueChange("re_enter_password", valueWithoutSpaces);
                                setFieldValue("re_enter_password", valueWithoutSpaces);
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowConfirmPassword}
                                      onMouseDown={this.handleMouseDownConfirmPassword}
                                    >
                                      {this.state.showConfirmPassword ?  <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                            {this.getErrorMessage(touched, errors, "re_enter_password")}
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="body1" className="passValidation">
                              Password requirements: minimum of 8 characters <b>USE AT LEAST 2</b> of the following classes:
                              <ul className="pass-list">
                                <li>lower case letters, upper case letter(s), number(s)</li>
                                <li>Special Character(s) (@#$^&*+=)</li>
                              </ul>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box className="agreeWrapper" >
                        <Box className="center">
                          <Checkbox className="checkboxStyle"  data-test-id="agreeTermBox" onChange={(e) =>
                            this.setSignUpFieldsCheckbox("agree_terms", e.target.checked)}
                            checked={this.state.agree_terms}
                          />
                          <Typography variant="body2" className="agree-to-typo" >
                            {" "}
                            Agree to{" "}
                            <Button style={{ fontFamily: 'Museo Sans, sans-serif' }} data-test-id="agreeBtnId" className="secondaryColorTerms" onClick={() => this.goToTermsAndCondition()}>Terms and Conditions</Button>
                          </Typography>
                        </Box>
                        <Box className="center">
                          <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            data-test-id="submitButtonId"
                            className="btnStyleCustom"
                            style={{ fontFamily: 'Museo Sans, sans-serif' }}
                            disabled={!(this.state.agree_terms && this.state.agree_Owner)}
                          >
                          Submit
                        </Button>
                          <Box>
                            <Backdrop
                              open={this.state.isLoading}
                              style={{ color: '#fff', zIndex: 1000 }}
                            >
                              <CircularProgress color="inherit" size={30} />
                            </Backdrop>
                          </Box>
                        <Snackbar open={this.state.successSnackbar} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                          <Alert
                            severity="success"
                            variant="filled"
                            className="alert-submission"
                            sx={{ width: '100%' , height:'50%', padding:'0px 12px 0px 12px'}}
                          >
                            Form submitted successfully!
                          </Alert>
                        </Snackbar>
                      </Box>
                      </Box>
                  </form>
                )}
              </Formik>

            </Box>
          </Box>
        </Box>

        <Footer {...this.props} />
        <Box
          display={{ xs: "block", sm: "flex" }}
          justifyContent="space-between"

        >
        </Box>
        <Dialog
          data-test-id="dialog"
          open={
            this.state.openDialogName === 'Add' ||
            this.state.openDialogName === 'Edit'
          }
          fullWidth={false}
          style={webStyle.mainWrapper}
          className="automated-prioritization"
          maxWidth={"sm"}
          transitionDuration={0}
          scroll="body"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogContent
            style={{ padding: '0px', width: '100%' }}
            data-test-id="form"
          >
            <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
              <CloseIcon
                data-test-id="closeBtn"
                onClick={this.onCancel}
                style={{
                  cursor: "pointer",
                  fontSize: "35px",
                  color: "#787878",
                }}
              />
            </Box>
            <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
              <Box
                id="modal-title"
                mb="20px"
                // fontFamily="Roboto, Helvetica, Arial, sans-serif"
                fontWeight="bolder"
                fontSize={{ xs: "20px", sm: "30px" }}
              >
                {this.state.openDialogName + " user"}
              </Box>
              <Formik
                data-test-id="formik"
                initialValues={{
                  firstName: this.state.firstName,
                  lastName: this.state.lastName,
                  phoneNumber: this.state.phoneNumber,
                  email: this.state.email,
                  organization: this.state.organization,
                  teamName: this.state.teamName,
                  userType: this.state.userType,
                  rating: this.state.rating,
                  gender: this.state.gender,
                  address: this.state.address,
                  country: this.state.country,
                  state: this.state.state,
                  city: this.state.city,
                  file: this.state.file,
                }}
                validationSchema={this.userSchema}
                onSubmit={(values) => {
                  this.state.openDialogName === "Add"
                    ? this.onAddTask()
                    : this.onEditTask();
                }}
              >
                {({ errors, touched, setFieldValue, handleSubmit }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          First name
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <TextField
                          data-test-id="firstName"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="First name"
                          value={this.state.firstName}
                          onChange={(event) => {
                            this.onValueChange("firstName", event.target.value);
                            setFieldValue("firstName", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "firstName")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Last name
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <TextField
                          data-test-id="lastName"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Last name"
                          value={this.state.lastName}
                          onChange={(event) => {
                            this.onValueChange("lastName", event.target.value);
                            setFieldValue("lastName", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "lastName")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Phone number
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <TextField
                          data-test-id="phoneNumber"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Phone number"
                          value={this.state.phoneNumber}
                          onChange={(event) => {
                            this.onValueChange(
                              "phoneNumber",
                              event.target.value
                            );
                            setFieldValue("phoneNumber", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "phoneNumber")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Email
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <TextField
                          data-test-id="email"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Email"
                          value={this.state.email}
                          onChange={(event) => {
                            this.onValueChange("email", event.target.value);
                            setFieldValue("email", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "email")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Organization
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <TextField
                          data-test-id="organization"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Organization"
                          value={this.state.organization}
                          onChange={(event) => {
                            this.onValueChange(
                              "organization",
                              event.target.value
                            );
                            setFieldValue("organization", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "organization")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Team name
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <TextField
                          data-test-id="teamName"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Team name"
                          value={this.state.teamName}
                          onChange={(event) => {
                            this.onValueChange("teamName", event.target.value);
                            setFieldValue("teamName", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "teamName")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          I am
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        {this.getErrorMessage(touched, errors, "userType")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Gender
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        {this.getErrorMessage(touched, errors, "gender")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Rating
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        {this.getErrorMessage(touched, errors, "rating")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Address
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <TextField
                          data-test-id="address"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Address"
                          value={this.state.address}
                          onChange={(event) => {
                            this.onValueChange("address", event.target.value);
                            setFieldValue("address", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "address")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Country
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <TextField
                          data-test-id="country"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Country"
                          value={this.state.country}
                          onChange={(event) => {
                            this.onValueChange("country", event.target.value);
                            setFieldValue("country", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "country")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          State
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <TextField
                          data-test-id="state"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="State"
                          value={this.state.state}
                          onChange={(event) => {
                            this.onValueChange("state", event.target.value);
                            setFieldValue("state", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "state")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          City
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <TextField
                          data-test-id="city"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="City"
                          value={this.state.city}
                          onChange={(event) => {
                            this.onValueChange("city", event.target.value);
                            setFieldValue("city", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "city")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          File
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <input
                          data-test-id="file"
                          type="file"
                          onChange={(event) => {
                            const file = event.target.files
                              ? event.target.files[0]
                              : null;
                            this.onFileChange(file);
                            setFieldValue("file", file);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "file")}
                      </Grid>
                    </Grid>
                    <Box mt="30px" display="flex">
                      <CancelButton
                        data-test-id="cancelBtn"
                        onClick={this.onCancel}
                      >
                        Cancel
                      </CancelButton>
                      <SubmitButton type="submit">
                        {this.state.openDialogName}
                      </SubmitButton>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

const userTypeList: Dropdown[] = [
  { label: "Tenant", value: "Tenant" },
  { label: "Owner", value: "Owner" },
  { label: "Supervisor", value: "Supervisor" },
];

const ratingList: Dropdown[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const genderList: Dropdown[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  border: "1px solid rgb(98, 0, 238)",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end",
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
};
// Customizable Area End
