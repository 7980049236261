import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  travelArticleData:any[];
  currentIndex:number;
  selectedBlog: any;
  bannerImage:string;
  citiesList:string[];
  categoryList:string[];
  searchChange:string;
  cityChange:string;
  categoryChange:string;
  shareableUrl:string;
  setOpenShare:boolean;
  loading:boolean;
  autoScrollEnabled: boolean;
  intervalId: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TravelBlogArticleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  addsearchDataCallId:string ='';
  apiArticleGetDataCallId:string = '';
  getShareUrlCallId:string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      travelArticleData:[],
      currentIndex:0,
      selectedBlog: null,
      bannerImage:"",
      citiesList:[],
      categoryList:[],
      searchChange:"",
      cityChange:"",
      categoryChange:"",
      shareableUrl:"",
      setOpenShare:false,
      loading:false,
      autoScrollEnabled: true,
      intervalId: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
      
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetDataCallId !== null &&
      this.apiGetDataCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      
      if (responseJson && !responseJson.errors) {
        this.setState({
          travelArticleData: responseJson.blog_post,
          bannerImage: responseJson.banner_image?.url,
          citiesList: responseJson.cities,
          categoryList: responseJson.categories,
          loading:false
      });
      } else {
        alert(responseJson.errors)
      }
    }  else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addsearchDataCallId !== null &&
      this.addsearchDataCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      
      if (responseJson && !responseJson.errors) {
        this.setState({
          travelArticleData: responseJson.data,
          citiesList: responseJson.cities,
          categoryList: responseJson.categories
      });
      } else {
        alert(responseJson.errors)
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiArticleGetDataCallId !== null &&
      this.apiArticleGetDataCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      
      if (responseJson && !responseJson.errors) {
        this.setState({loading:false})
        this.setState({ selectedBlog: responseJson }, () => {
          this.props.navigation.navigate("Article", { id: responseJson.id })
        });
      } else {
        alert(responseJson.errors)
      }
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getShareUrlCallId != null &&
      this.getShareUrlCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        this.setState({ shareableUrl: responseJson.link });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {

    this.startAutoScroll();
    
    const lastPathSegment = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    if (lastPathSegment !== 'TravelBlog') {
      let article_Id = lastPathSegment; 
      this.setState({loading:true})
      this.getArticleData(article_Id)
    }else{
      this.setState({loading:true})
      this.getTravelArticleData()
    }
  }  

  async componentWillUnmount() {
    this.stopAutoScroll();
  }

  handleClickOpenSharePopup = (id:string) => {
    this.setState({setOpenShare:true})
    this.getShareUrlFunc(id);
  };

  handleClose = () => {
    this.setState({setOpenShare:false})
  };

  goBackToTravel = () => {
    this.props.navigation.navigate('TravelBlog');
    this.setState({selectedBlog:null})
    this.getTravelArticleData();
   }

   startAutoScroll = () => {
    const intervalId = setInterval(() => {
      if (this.state.autoScrollEnabled) {
        this.handleNext();
      }
    }, 5000);

    this.setState({ intervalId });
  };


  stopAutoScroll = () => {
    const { intervalId } = this.state;
    if (intervalId) {
      clearInterval(intervalId);
      this.setState({ intervalId: null });
    }
  };

   handlePrev = () => {
    this.setState((prevState) => ({
      currentIndex: prevState.currentIndex > 0 ? prevState.currentIndex - 1 : 2 
    }));
  };
  
  handleNext = () => {
    this.setState((prevState) => ({
      currentIndex: prevState.currentIndex < 2 ? prevState.currentIndex + 1 : 0 
    }));
  };  


  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchChange: event.target.value });
  };

  setCategoryList = (value: string) => {
    this.setState({ categoryChange: value }, () => {
      this.getSearchData();
    });
  };

  setCityList = (value: string) => {
    this.setState({ cityChange: value }, () => {
      this.getSearchData();
    });
  };
  
  getTravelArticleData = async () => {

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTravelData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getArticleData = async (id:string) => {

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiArticleGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.articleData}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getSearchData = async () => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addsearchDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchFilter}?query=${this.state.searchChange}&category=${this.state.categoryChange}&city=${this.state.cityChange}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getShareUrlFunc = (business_Id:string) =>{

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getShareUrlCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShareUrl}?id=${business_Id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  // Customizable Area End
}
